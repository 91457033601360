






import { Component } from 'vue-property-decorator'

import NavigationCTABoxUi from '../../../../../modules/NavigationCTABox/ui/NavigationCTABox.ui.vue'
import { AbstractAddon } from '../../../abstract/addon-abstract'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<NavigationCTABoxAddon>({
  name: 'NavigationCTABoxAddon',
  components: { NavigationCTABoxUi }
})
export class NavigationCTABoxAddon extends AbstractAddon {}

export default NavigationCTABoxAddon
